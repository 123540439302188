import React, { useState } from 'react';
import { X, Check } from 'lucide-react';
import styles from '../styles/filterModal.module.css';
import { INTERESTS } from './interests'; // Import the interests

const FilterModal = ({ 
  initialFilters, 
  onApplyFilters, 
  onClose 
}) => {
  const [localFilters, setLocalFilters] = useState({
    ageRange: initialFilters.ageRange || [18, 50],
    interests: initialFilters.interests || [],
    distance: initialFilters.distance || 50 // Add distance to initial state
  });

  const handleFilterChange = (type, value) => {
    setLocalFilters(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleApplyFilters = () => {
    onApplyFilters(localFilters);
    onClose();
  };

  return (
    <div className={styles.filterModalFullPage}>
      <div className={styles.filterModalContent}>
        <div className={styles.filterModalHeader}>
          <button 
            className={styles.closeButton} 
            onClick={onClose}
          >
            <X size={24} />
          </button>
          <h2>Advanced Filters</h2>
          <button 
            className={styles.applyButton} 
            onClick={handleApplyFilters}
          >
            <Check size={24} />
          </button>
        </div>

        {/* Age Range Filter */}
        <div className={styles.filterSection}>
          <label>Age Range</label>
          <div className={styles.ageRangeContainer}>
            <div className={styles.rangeInputWrapper}>
              <span>Min Age: {localFilters.ageRange[0]}</span>
              <input
                type="range"
                min="18"
                max="50"
                value={localFilters.ageRange[0]}
                onChange={(e) => handleFilterChange('ageRange', [
                  parseInt(e.target.value), 
                  localFilters.ageRange[1]
                ])}
              />
            </div>
            <div className={styles.rangeInputWrapper}>
              <span>Max Age: {localFilters.ageRange[1]}</span>
              <input
                type="range"
                min="18"
                max="50"
                value={localFilters.ageRange[1]}
                onChange={(e) => handleFilterChange('ageRange', [
                  localFilters.ageRange[0], 
                  parseInt(e.target.value)
                ])}
              />
            </div>
          </div>
        </div>

        {/* Interests Filter */}
        <div className={styles.filterSection}>
          <label>Interests</label>
          <div className={styles.interestsGrid}>
            {INTERESTS.map(interest => (
              <button
                key={interest.name}
                className={`${styles.interestChip} ${
                  localFilters.interests.includes(interest.name) ? styles.selectedInterest : ''
                }`}
                onClick={() => {
                  const currentInterests = localFilters.interests;
                  const newInterests = currentInterests.includes(interest.name)
                    ? currentInterests.filter(i => i !== interest.name)
                    : [...currentInterests, interest.name];
                  handleFilterChange('interests', newInterests);
                }}
              >
                {interest.name}
              </button>
            ))}
          </div>
        </div>

        {/* Location Distance Filter */}
        <div className={styles.filterSection}>
          <label>Location Distance</label>
          <div className={styles.distanceSliderContainer}>
            <input 
              type="range" 
              min="1" 
              max="100" 
              step="1"
              value={localFilters.distance}
              onChange={(e) => handleFilterChange('distance', parseInt(e.target.value))}
              className={styles.distanceSlider}
            />
            <span>Distance: {localFilters.distance} km</span>
          </div>
        </div>

        {/* Additional Filters can be added here */}
      </div>
    </div>
  );
};

export default FilterModal;