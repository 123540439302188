export const INTERESTS = [
  { name: '🏋️ Fitness' },
  { name: '🍳 Cooking' },
  { name: '📚 Reading' },
  { name: '🎨 Art' },
  { name: '🎮 Gaming' },
  { name: '🏕️ Hiking' },
  { name: '🎵 Music' },
  { name: '🧘 Meditation' },
  { name: '🌱 Sustainability' },
  { name: '🏸 Sports' },
  { name: '🍷 Wine Tasting' },
  { name: '🌍 Travel' },
  { name: '🐶 Pet Lover' },
  { name: '🎭 Theater' },
  { name: '🧑‍💻 Technology' }
];