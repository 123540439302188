import React, { useState, useEffect, useRef } from 'react';
import { MapPin, Briefcase, Heart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import axios from 'axios';
import BottomNav from './BottomNav';
import styles from '../styles/forYou.module.css';

const ForYouPage = () => {
	
  useRequireAuth();
  useProfileCompletenessCheck();
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendations();
  }, []);
  
  const fetchRecommendations = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recommendations/foryou`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setRecommendations(response.data.recommendations);
      setError(null);

    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to load recommendations';
      setError(errorMessage);
      console.error('Recommendations fetch error:', err);
      
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (profileId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/discover/like/${profileId}`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.isMatch) {
        console.log("It's a match!");
      }

      // Remove the liked profile from recommendations
      setRecommendations(prev => prev.filter(profile => profile._id !== profileId));

    } catch (err) {
      console.error('Like profile error:', err);
    }
  };

  if (loading) {
    return (
      <div>
        <div className={styles.loadingContainer}>
          <div className={styles.topNavbar}>
            <div className={styles.topNavContent}>
              <div className={styles.topNavTitle}>For You</div>
            </div>
          </div>
          <div className={styles.loadingSpinner} />
        </div>
        <BottomNav />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.topNavbar}>
        <div className={styles.topNavContent}>
          <div className={styles.topNavTitle}>For You</div>
        </div>
      </div>
      <div className={styles.forYouWrapper}>
        {error && (
          <div className={styles.errorBanner}>
            <p className={styles.errorMessage}>{error}</p>
          </div>
        )}

        <div className={styles.sectionContainer}>
          <h2 className={styles.sectionTitle}>Based on Profiles You Liked</h2>
          
          <div className={styles.cardsSlider}>
            {recommendations.length > 0 ? (
              recommendations.map(profile => (
                <div key={profile._id} className={styles.profileCard}>
                  <div className={styles.imageContainer}>
                    <img 
                      src={profile.profilePhoto}
                      alt={profile.name}
                      className={styles.profileImage}
                    />
                    <button 
                      className={styles.likeButton}
                      onClick={() => handleLike(profile._id)}
                    >
                      <Heart size={20} />
                    </button>
                  </div>

                  <div className={styles.profileInfo}>
                    <h3 className={styles.profileName}>
                      {profile.name}, {profile.age}
                    </h3>
                    
                    <div className={styles.profileDetails}>
                      <div className={styles.detailItem}>
                        <MapPin size={16} />
                        <span>{profile.location}</span>
                      </div>
                      <div className={styles.detailItem}>
                        <Briefcase size={16} />
                        <span>{profile.occupation}</span>
                      </div>
                    </div>

                    <div className={styles.compatibilityScore}>
                      <span>Match</span>
                      <div className={styles.scoreBar}>
                        <div 
                          className={styles.scoreProgress} 
                          style={{ width: `${profile.compatibilityScore}%` }} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noRecommendations}>
                <p>No recommendations available</p>
                <button 
                  onClick={fetchRecommendations}
                  className={styles.refreshButton}
                >
                  Refresh
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default ForYouPage;