import React, { useState, useEffect, useRef } from 'react';
import { motion, useMotionValue, useTransform, AnimatePresence } from 'framer-motion';
import { Heart, X, MapPin, Briefcase, SlidersHorizontal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import BottomNav from './BottomNav';
import FilterModal from './FilterModal';
import styles from '../styles/discover.module.css';

const DiscoverPage = () => {
  useRequireAuth();
  useProfileCompletenessCheck();
  const [profiles, setProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({
    ageRange: [18, 50],
    interests: [],
    maxDistance: 50
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/discover/recommendations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          ageMin: filters.ageRange[0],
          ageMax: filters.ageRange[1],
          interests: filters.interests.join(','),
          maxDistance: filters.maxDistance,
          page: 1,
          limit: 10
        }
      });

      const sanitizedProfiles = response.data.profiles.map(profile => ({
        ...profile,
        interests: profile.interests || [],
        galleryImages: profile.galleryImages || [],
        compatibilityScore: profile.compatibilityScore || 0,
        location: profile.location || 'Unknown location',
        occupation: profile.occupation || 'Not specified',
        bio: profile.bio || '',
        profilePhoto: profile.profilePhoto || '/default-profile.jpg'
      }));

      setProfiles(sanitizedProfiles);
      setCurrentIndex(0);
      setError(null);

    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to load profiles';
      setError(errorMessage);
      console.error('Profile fetch error:', err);
      
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSwipeOrClick = async (direction) => {
    if (!profiles[currentIndex]) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      if (direction === 'right') {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/discover/like/${profiles[currentIndex]._id}`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.isMatch) {
          console.log("It's a match!");
          // TODO: Implement match logic (e.g., show match screen)
        }
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/discover/pass/${profiles[currentIndex]._id}`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }

      if (currentIndex < profiles.length - 1) {
        setCurrentIndex(prev => prev + 1);
      } else {
        fetchProfiles();
      }

    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to process profile';
      setError(errorMessage);
      console.error('Profile interaction error:', err);

      if (err.response?.status === 401) {
        navigate('/login');
      }
    }
  };

  if (loading) {
    return (
      <div>
        <div className={styles.topNavbar}>
          <div className={styles.topNavContent}>
            <div className={styles.topNavTitle}>Bliss Swipe</div>
            <div className={styles.topNavActions}>
              <button 
                className={styles.filterButton}
                onClick={() => setShowFilterModal(true)}
              >
                <SlidersHorizontal size={24} />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner} />
        </div>
        <BottomNav />
      </div>
    );
  }

  if (!profiles.length) {
    return (
      <div>
        <div className={styles.topNavbar}>
          <div className={styles.topNavContent}>
            <div className={styles.topNavTitle}>Bliss Swipe</div>
            <div className={styles.topNavActions}>
              <button 
                className={styles.filterButton}
                onClick={() => setShowFilterModal(true)}
              >
                <SlidersHorizontal size={24} />
              </button>
            </div>
          </div>
        </div>
        
        <div className={styles.noProfilesContainer}>
          <h2>No more profiles to show</h2>
          <p>Try adjusting your filters to see more people</p>
          <button 
            onClick={fetchProfiles}
            className={styles.refreshButton}
          >
            Refresh Profiles
          </button>
        </div>
        
        {showFilterModal && (
          <FilterModal 
            initialFilters={filters}
            onApplyFilters={(newFilters) => {
              setFilters(newFilters);
              fetchProfiles();
            }}
            onClose={() => setShowFilterModal(false)}
          />
        )}
        
        <BottomNav />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.topNavbar}>
        <div className={styles.topNavContent}>
          <div className={styles.topNavTitle}>Bliss Swipe</div>
          <div className={styles.topNavActions}>
            <button 
              className={styles.filterButton}
              onClick={() => setShowFilterModal(true)}
            >
              <SlidersHorizontal size={24} />
            </button>
          </div>
        </div>
      </div>
      
      <div className={styles.discoverWrapper}>
        {error && (
          <div className={styles.errorBanner}>
            <X size={20} />
            <p className={styles.errorMessage}>{error}</p>
          </div>
        )}

        <div className={styles.discoverContainer}>
          <AnimatePresence mode="wait">
            {profiles.slice(currentIndex, currentIndex + 1).map((profile) => (
              <SwipeCard 
                key={profile._id} 
                profile={profile} 
                onSwipeOrClick={handleSwipeOrClick}
              />
            ))}
          </AnimatePresence>
        </div>
      </div>

      {showFilterModal && (
        <FilterModal 
          initialFilters={filters}
          onApplyFilters={(newFilters) => {
            setFilters(newFilters);
            fetchProfiles();
          }}
          onClose={() => setShowFilterModal(false)}
        />
      )}
      
      <BottomNav />
    </div>
  );
};

const SwipeCard = ({ profile, onSwipeOrClick }) => {
  const x = useMotionValue(0);
  const rotate = useTransform(x, [-300, 0, 300], [-20, 0, 20]);
  const opacity = useTransform(x, [-300, 0, 300], [0.5, 1, 0.5]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselRef = useRef(null);

  const handleDragEnd = (_, info) => {
    const { offset } = info;
    
    if (Math.abs(offset.x) > 150) {
      const swipeDirection = offset.x > 0 ? 'right' : 'left';
      onSwipeOrClick(swipeDirection);
    }
  };

  // Combine profile photo and gallery images
  const allImages = [profile.profilePhoto, ...(profile.galleryImages || [])];

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleScroll = () => {
      if (carousel) {
        const scrollLeft = carousel.scrollLeft;
        const imageWidth = carousel.clientWidth;
        const newIndex = Math.round(scrollLeft / imageWidth);
        setCurrentImageIndex(newIndex);
      }
    };

    carousel.addEventListener('scroll', handleScroll);
    return () => {
      carousel.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.div
      key={profile._id}
      className={styles.profileCard}
      style={{
        x,
        rotate,
        opacity,
        cursor: 'grab',
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.5}
      onDragEnd={handleDragEnd}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ 
        opacity: 0, 
        scale: 0.5, 
        transition: { duration: 0.2 } 
      }}
    >
      <div className={styles.profileImagesCarousel}>
        <div 
          ref={carouselRef}
          className={styles.imagesScrollContainer} 
          style={{ 
            scrollSnapType: 'x mandatory', 
            overflowX: 'scroll', 
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}
        >
          {allImages.map((image, index) => (
            <img 
              key={index} 
              src={image}
              alt={`Profile ${index + 1}`}
              className={styles.profileCarouselImage}
              style={{ 
                scrollSnapAlign: 'start',
                minWidth: '100%', 
                objectFit: 'cover' 
              }}
            />
          ))}
        </div>

        {/* Image Progress Dots */}
        <div className={styles.imageProgressContainer}>
          {allImages.map((_, index) => (
            <div 
              key={index} 
              className={`${styles.progressDot} ${index === currentImageIndex ? styles.activeDot : ''}`}
            />
          ))}
        </div>
      </div>

      <div className={styles.profileInfo}>
        <h2 className={styles.profileName}>
          {profile.name}, {profile.age}
        </h2>
        
        <div className={styles.profileDetails}>
          <div className={styles.detailItem}>
            <MapPin size={18} />
            <span>{profile.location}</span>
          </div>
          <div className={styles.detailItem}>
            <Briefcase size={18} />
            <span>{profile.occupation}</span>
          </div>
        </div>

        <div className={styles.bioSection}>
          <p>{profile.bio}</p>
        </div>

        {profile.interests.length > 0 && (
          <div className={styles.interestsSection}>
            <div className={styles.interestsGrid}>
              {profile.interests.map((interest, index) => (
                <span key={index} className={styles.interestTag}>
                  {interest}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className={styles.compatibilityScore}>
          <span>Compatibility</span>
          <div className={styles.scoreBar}>
            <div 
              className={styles.scoreProgress} 
              style={{ 
                width: `${profile.compatibilityScore}%` 
              }} 
            />
          </div>
        </div>

        <div className={styles.interactionButtons}>
          <button 
            className={styles.dislikeButton} 
            onClick={() => onSwipeOrClick('left')}
          >
            <X size={24} />
          </button>
          <button 
            className={styles.likeButton} 
            onClick={() => onSwipeOrClick('right')}
          >
            <Heart size={24} />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default DiscoverPage;