import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Hook to check authentication and redirect if not logged in
export const useRequireAuth = (redirectTo = '/login') => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate(redirectTo);
        return;
      }

      try {
        // Verify the token is still valid
        await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        // Token is invalid or expired
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        navigate(redirectTo);
      }
    };

    checkAuth();
  }, [navigate, redirectTo]);
};

// Higher-Order Component for authentication protection
export const withAuth = (WrappedComponent, redirectTo = '/login') => {
  return (props) => {
    useRequireAuth(redirectTo);
    return <WrappedComponent {...props} />;
  };
};

// Redirect already logged-in users
export const useRedirectIfAuthenticated = (redirectTo = '/discover') => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    const checkAuth = async () => {
      if (token) {
        try {
          // Verify the token is still valid
          await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/me`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          navigate(redirectTo);
        } catch (error) {
          // Token is invalid or expired
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
        }
      }
    };

    checkAuth();
  }, [navigate, redirectTo]);
};