import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Check } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import { useRequireAuth } from './AuthCheck';
import ProfileCompletionSteps from './ProfileCompletionSteps';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/profileCompletion.module.css';

const ProfileCompletion = () => {
  useRequireAuth();
  const [profileData, setProfileData] = useState({});
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [mandatoryFields] = useState(["name", "email", "bio", "birthday", "location", "profilePhoto", "galleryImages"]);
  const [nonMandatoryFields] = useState(['interests', 'occupation']);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [uploadingGallery, setUploadingGallery] = useState(false);
  const navigate = useNavigate();

  const fetchProfileData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) navigate('/login');

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const profile = response.data;
      const incomplete = mandatoryFields.filter((field) => {
        if (field === "galleryImages") return !profile[field] || profile[field].length === 0;
        if (field === "birthday") return !profile[field];
        return !profile[field];
      });

      const nonMandatoryIncomplete = nonMandatoryFields.filter(
        (field) => !profile[field] || profile[field].length === 0
      );

      setProfileData(profile);
      setIncompleteFields([...incomplete, ...nonMandatoryIncomplete]);
    } catch (error) {
      console.error("Failed to fetch profile data", error);
      toast.error("Failed to load profile data");
    }
  }, [navigate, mandatoryFields, nonMandatoryFields]);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const handleFileUpload = async (field, files) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();

    files.forEach((file) => formData.append(field, file));

    try {
      if (field === "profilePhoto") {
        setUploadingPhoto(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/profile/me/photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        setProfileData((prev) => ({ ...prev, profilePhoto: response.data.profilePhoto }));
        toast.success("Profile photo uploaded successfully");
      } else if (field === "galleryImages") {
        setUploadingGallery(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/profile/me/gallery`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        setProfileData((prev) => ({ ...prev, galleryImages: response.data.galleryImages }));
        toast.success("Gallery images uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading files", error);
      toast.error("Failed to upload files");
    } finally {
      setUploadingPhoto(false);
      setUploadingGallery(false);
    }
  };

  const handleNextStep = async (field, value) => {
    const token = localStorage.getItem('token');
    
    // Check if mandatory fields are empty
    if (mandatoryFields.includes(field) && (!value || 
        (typeof value === 'string' && value.trim() === '') || 
        (Array.isArray(value) && value.length === 0))) {
      toast.error(`${field} is required`);
      return;
    }

    try {
      if (field === "profilePhoto" || field === "galleryImages") {
        await handleFileUpload(field, value);
      } else {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/profile/me`, 
          { [field]: value }, 
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProfileData((prev) => ({ ...prev, [field]: value }));
      }

      if (currentStep === incompleteFields.length - 1) {
        await handleCompleteProfile();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    } catch (error) {
      console.error(`Failed to update ${field}`, error);
      toast.error(`Failed to update ${field}`);
    }
  };

  const handleSkipStep = async () => {
    const currentField = incompleteFields[currentStep];
    const isMandatory = mandatoryFields.includes(currentField);

    if (isMandatory) return;  // Block skip for mandatory fields

    if (currentStep === incompleteFields.length - 1) {
      await handleCompleteProfile();
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleCompleteProfile = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/profile/me`, profileData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Profile completed successfully");
      navigate('/discover');
    } catch (error) {
      console.error("Failed to complete profile", error);
      toast.error("Failed to save profile");
    }
  };

  if (!profileData) return <div>Loading...</div>;

  const currentField = incompleteFields[currentStep];
  const isMandatoryStep = mandatoryFields.includes(currentField);

  return (
    <div className={styles.fullPageWrapper}>
      <div className={styles.progressIndicator}>
        {incompleteFields.map((_, index) => (
          <div 
            key={index} 
            className={`${styles.progressDot} ${index <= currentStep ? styles.activeDot : ''}`}
          />
        ))}
      </div>
      <div className={styles.profileCompletionHeader}>
        <h1>Complete Your Profile</h1>
      </div>
      {incompleteFields.length > currentStep ? (
        <ProfileCompletionSteps
          field={currentField}
          profileData={profileData}
          onNext={handleNextStep}
          onSkip={!isMandatoryStep ? handleSkipStep : null}
          uploadingPhoto={uploadingPhoto}
          uploadingGallery={uploadingGallery}
          isMandatory={isMandatoryStep}
          isLastStep={currentStep === incompleteFields.length - 1}
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default ProfileCompletion;