class WebSocketService {
  constructor() {
    this.ws = null;
    this.messageHandlers = new Set();
    this.connectionHandlers = new Set();
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 5000;
    this.token = null;
    this.isConnecting = false;
    this.messageStore = new Map(); // Store messages by chat partner ID
    this.pendingMessages = new Map(); // Store messages waiting for server confirmation
  }

  connect(token) {
    if (this.isConnecting) return;
    
    this.token = token;
    this.establishConnection();
  }

  establishConnection() {
    if (!this.token || this.isConnecting) return;

    this.isConnecting = true;
    const wsUrl = `${process.env.REACT_APP_WS_URL || 'wss://stupid-cow-b34stx-e5ab1a54.koyeb.app'}/ws?token=${this.token}`;
    this.ws = new WebSocket(wsUrl);

    this.ws.onopen = () => {
      console.log('WebSocket connected');
      this.isConnecting = false;
      this.reconnectAttempts = 0;
      this.connectionHandlers.forEach(handler => handler({
        status: 'connected',
        attempt: this.reconnectAttempts
      }));

      this.sendMessage({
        type: 'auth',
        token: this.token
      });
    };

    this.ws.onclose = (event) => {
      console.log('WebSocket disconnected:', event.code, event.reason);
      this.isConnecting = false;
      this.connectionHandlers.forEach(handler => handler({
        status: 'disconnected',
        attempt: this.reconnectAttempts
      }));

      if (this.token && this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
        setTimeout(() => this.establishConnection(), this.reconnectDelay * this.reconnectAttempts);
      } else {
        this.connectionHandlers.forEach(handler => handler({
          status: 'failed',
          attempt: this.reconnectAttempts,
          error: 'Max reconnection attempts reached'
        }));
      }
    };

    this.ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === 'auth_success') {
          console.log('Authentication successful');
          localStorage.setItem('userId', data.userId);
          this.connectionHandlers.forEach(handler => handler({
            status: 'authenticated',
            userId: data.userId,
            username: data.username
          }));
        } else if (data.type === 'message') {
          this.handleIncomingMessage(data);
        } else if (data.type === 'message_status') {
          this.handleMessageStatus(data);
        }
        
        this.messageHandlers.forEach(handler => handler(data));
      } catch (error) {
        console.error('WebSocket message parsing error:', error);
        this.messageHandlers.forEach(handler => handler({
          type: 'error',
          error: 'Message parsing failed',
          originalMessage: event.data
        }));
      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.isConnecting = false;
      this.connectionHandlers.forEach(handler => handler({
        status: 'error',
        attempt: this.reconnectAttempts,
        error: error.message
      }));
    };
  }

  // Handle incoming messages and store them
  handleIncomingMessage(data) {
    const message = {
      id: data.messageId,
      sender: data.senderId,
      receiver: data.receiverId,
      content: data.message,
      timestamp: data.timestamp,
      status: 'received'
    };

    const chatPartnerId = data.senderId;
    this.addMessageToStore(chatPartnerId, message);
  }

  // Handle message status updates
  handleMessageStatus(data) {
    const pendingMessage = this.pendingMessages.get(data.tempId);
    if (pendingMessage) {
      const chatPartnerId = pendingMessage.receiver;
      const updatedMessage = {
        ...pendingMessage,
        id: data.messageId,
        status: data.status
      };
      
      this.addMessageToStore(chatPartnerId, updatedMessage);
      this.pendingMessages.delete(data.tempId);
    }
  }

  // Add message to store
  addMessageToStore(chatPartnerId, message) {
    if (!this.messageStore.has(chatPartnerId)) {
      this.messageStore.set(chatPartnerId, []);
    }
    const messages = this.messageStore.get(chatPartnerId);
    const messageIndex = messages.findIndex(m => m.id === message.id || m.tempId === message.tempId);
    
    if (messageIndex === -1) {
      messages.push(message);
    } else {
      messages[messageIndex] = message;
    }
    
    messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }

  // Load chat history for a specific user
  async loadChatHistory(userId) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/history/${userId}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const history = await response.json();
      this.messageStore.set(userId, history);
      return history;
    } catch (error) {
      console.error('Error loading chat history:', error);
      throw error;
    }
  }

  // Get messages for a specific chat partner
  getMessages(chatPartnerId) {
    return this.messageStore.get(chatPartnerId) || [];
  }

  // Send a new message
  async sendChatMessage(receiverId, content) {
    const tempId = `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const timestamp = new Date().toISOString();
    const currentUserId = localStorage.getItem('userId');

    const message = {
      tempId,
      sender: currentUserId,
      receiver: receiverId,
      content,
      timestamp,
      status: 'pending'
    };

    // Store pending message
    this.pendingMessages.set(tempId, message);
    this.addMessageToStore(receiverId, message);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
        body: JSON.stringify({
          receiverId,
          message: content,
          tempId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Update message status to failed
      const failedMessage = this.pendingMessages.get(tempId);
      if (failedMessage) {
        failedMessage.status = 'failed';
        this.addMessageToStore(receiverId, failedMessage);
      }
      throw error;
    }
  }

  addMessageHandler(handler) {
    this.messageHandlers.add(handler);
    return () => this.messageHandlers.delete(handler);
  }

  addConnectionHandler(handler) {
    this.connectionHandlers.add(handler);
    return () => this.connectionHandlers.delete(handler);
  }

  sendMessage(message) {
    if (this.ws?.readyState === WebSocket.OPEN) {
      try {
        this.ws.send(JSON.stringify(message));
        return true;
      } catch (error) {
        console.error('Failed to send message:', error);
        return false;
      }
    }
    console.warn('WebSocket not connected, message not sent');
    return false;
  }

  disconnect() {
    this.token = null;
    this.reconnectAttempts = this.maxReconnectAttempts;
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    // Clear message stores on disconnect
    this.messageStore.clear();
    this.pendingMessages.clear();
  }

  getConnectionState() {
    if (!this.ws) return 'closed';
    return ['connecting', 'open', 'closing', 'closed'][this.ws.readyState];
  }
}

export const wsService = new WebSocketService();
