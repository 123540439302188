import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Eye, EyeOff, Mail, Lock } from 'lucide-react';
import { useRedirectIfAuthenticated } from './AuthCheck';
import styles from '../styles/login.module.css';
import loginBackground from '../assets/login_bg_desktop.png';
import Logo from '../assets/Logo.svg';

const Login = () => {
  useRedirectIfAuthenticated();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, formData);
      const { token } = response.data;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/me`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const profile = profileResponse.data;

      const requiredFields = ["name", "email", "birthday", "location", "profilePhoto", "galleryImages"];
      const isProfileComplete = requiredFields.every(field => profile[field]);

      if (isProfileComplete) {
        navigate('/discover');
      } else {
        navigate('/completeprofile');
      }

    } catch (err) {
      setError(
        err.response?.data?.message || 
        'Unable to sign in. Please check your credentials.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <div className={styles.loginBackground}>
          <img 
            src={loginBackground}
            alt="Background" 
            className={styles.backgroundImage} 
          />
          <div className={styles.loginOverlay}></div>
        </div>
        
        <div className={styles.loginContent}>
          <div className={styles.topRightLogo}>
            <img src={Logo} alt="Company Logo" className={styles.topRightLogoImage} />
          </div>

          <div className={styles.loginHeader}>
            <h1>Welcome Back</h1>
            <p>Sign in to continue your journey</p>
          </div>

          {error && (
            <div className={styles.errorBanner}>
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className={styles.loginForm}>
            {/* Rest of the form remains the same */}
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <Mail className={styles.inputIcon} />
                <input 
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <Lock className={styles.inputIcon} />
                <input 
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  disabled={isLoading}
                />
                <button 
                  type="button" 
                  className={styles.passwordToggle}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>
            </div>

            <div className={styles.forgotPassword}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>

            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? 'Signing In...' : 'Sign In'}
            </button>
          </form>

          <div className={styles.signupLink}>
            <p>Don't have an account?</p>
            <Link to="/signup">Create Account</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;