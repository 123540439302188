import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MessageCircle, Search, Settings, LogOut } from 'lucide-react';
import BottomNav from './BottomNav';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import styles from '../styles/inbox.module.css';

const Inbox = () => {
  useRequireAuth();
  useProfileCompletenessCheck();
  const [matches, setMatches] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return false;
    }
    return token;
  }, [navigate]);

  const handleError = useCallback((error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  }, [navigate]);

  const fetchMatches = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = checkAuth();
      if (!token) return;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/matches/mutual`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMatches(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [checkAuth, handleError]);

  useEffect(() => {
    checkAuth();
    fetchMatches();
  }, [checkAuth, fetchMatches]);

  const handleUserSelect = (matchedUser) => {
    setSelectedUser(matchedUser);
    navigate(`/chat/${matchedUser._id}`);
  };
  

  const filteredMatches = matches.filter(match => 
    match.user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    match.user.username?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={styles.pageContainer}>
      <div className={styles.inboxContainer}>
        <div className={styles.inboxLayout}>
          {/* Top Navigation */}
<div className={styles.topNavbar}>
  <div className={styles.topNavContent}>
    <div className={styles.topNavTitle}>Matches</div>
  </div>
</div>
          <main className={styles.inboxMain}>
            

            <div className={styles.messagesHeader}>
              <h2>Messages</h2>
              <span>{matches.length} matches</span>
            </div>

            {isLoading ? (
  <div>
        <div className={styles.loadingContainer}>
        {/* Top Navigation */}
<div className={styles.topNavbar}>
  <div className={styles.topNavContent}>
    <div className={styles.topNavTitle}>Matches</div>
  </div>
</div>
          <div className={styles.loadingSpinner} />
        </div>
        <BottomNav />
      </div>
) : (
  <div className={styles.usersList}>
                {filteredMatches.length > 0 ? (
                  filteredMatches.map((match) => (
                    <button
                      key={match.user._id}
                      className={`${styles.userCard} ${selectedUser?._id === match.user._id ? styles.selected : ''}`}
                      onClick={() => handleUserSelect(match.user)}
                    >
                      <div className={styles.userAvatarCircle}>
                        <span>{(match.user.name || match.user.username || 'U')[0].toUpperCase()}</span>
                      </div>
                      <div className={styles.userInfo}>
                        <p className={styles.name}>{match.user.name || match.user.username || 'User'}</p>
                        <p className={styles.status}>Click to start chat</p>
                      </div>
                      <div className={styles.onlineIndicator} />
                    </button>
                  ))
                ) : (
                  <div className={styles.emptyState}>
                    <MessageCircle size={24} />
                    <p>No matches found</p>
                  </div>
                )}
              </div>
            )}
          </main>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Inbox;