import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { wsService } from '../services/websocket';
import { ArrowLeft, Send, Clock, CheckCircle, XCircle, MoreVertical } from 'lucide-react';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import axios from 'axios';
import styles from '../styles/chat.module.css';

const Chat = () => {
  useRequireAuth();
  useProfileCompletenessCheck();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [chatUser, setChatUser] = useState(null);
  const messagesEndRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const { receiverId } = useParams();
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${receiverId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setChatUser(response.data);
      } catch (err) {
        console.error('Failed to fetch user details:', err);
        if (err.response?.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchUserDetails();
  }, [receiverId, navigate]);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat/history/${receiverId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        setMessages(response.data);
        scrollToBottom();
      } catch (err) {
        const errorMessage = err.response?.data?.message || 'Failed to load chat history';
        setError(errorMessage);
        console.error('Chat history error:', err);
        
        if (err.response?.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchChatHistory();
  }, [receiverId, navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    wsService.connect(token);

    const connectionHandler = (connected) => {
      setIsConnected(connected);
      if (connected) {
        setError(null);
      } else {
        setError('Connection lost. Reconnecting...');
      }
    };

    const messageHandler = (data) => {
      switch (data.type) {
        case 'message':
          if (data.senderId === receiverId || data.receiverId === receiverId) {
            setMessages(prev => [...prev, {
              id: data.messageId,
              sender: data.senderId,
              content: data.message,
              timestamp: data.timestamp,
              status: 'received'
            }]);
            scrollToBottom();
          }
          break;

        case 'message_status':
          setMessages(prev =>
            prev.map(msg =>
              msg.tempId === data.tempId
                ? { ...msg, id: data.messageId, status: data.status }
                : msg
            )
          );
          break;

        case 'typing':
          if (data.senderId === receiverId) {
            setIsTyping(true);
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current);
            }
            typingTimeoutRef.current = setTimeout(() => {
              setIsTyping(false);
            }, 3000);
          }
          break;

        case 'error':
          setError(data.message);
          break;

        default:
          console.warn('Unhandled message type:', data.type);
      }
    };

    const cleanupConnection = wsService.addConnectionHandler(connectionHandler);
    const cleanupMessage = wsService.addMessageHandler(messageHandler);

    return () => {
      cleanupConnection();
      cleanupMessage();
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [receiverId, navigate]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !isConnected) return;

    const tempId = Date.now().toString();
    const messageData = {
      type: 'message',
      receiverId,
      message: newMessage.trim(),
      tempId
    };

    try {
      const sent = await wsService.sendMessage(messageData);

      setMessages(prev => [...prev, {
        tempId,
        sender: localStorage.getItem('userId'),
        content: newMessage,
        timestamp: new Date(),
        status: sent ? 'sending' : 'failed'
      }]);

      setNewMessage('');
      scrollToBottom();
    } catch (err) {
      console.error('Failed to send message:', err);
      setError('Failed to send message. Please try again.');
    }
  };

  const handleTyping = (e) => {
    setNewMessage(e.target.value);
    
    if (isConnected) {
      wsService.sendMessage({
        type: 'typing',
        receiverId
      });
    }
  };

  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  return (
    <div className={styles.chatLayout}>
      {/* Header */}
      <div className={styles.chatHeader}>
        <div className={styles.headerContent}>
          <button
            className={styles.backButton}
            onClick={() => navigate('/inbox')}
            aria-label="Back to inbox"
          >
            <ArrowLeft size={24} />
          </button>
          
          {chatUser && (
            <div className={styles.chatUserInfo}>
              <div className={styles.chatUserAvatar}>
                {chatUser.name[0].toUpperCase()}
              </div>
              <div className={styles.chatUserDetails}>
                <h2>{chatUser.name}</h2>
                <span>{isConnected ? 'online' : 'offline'}</span>
              </div>
            </div>
          )}
          
          <button 
            className={styles.chatOptionsButton}
            aria-label="More options"
          >
            <MoreVertical size={24} />
          </button>
        </div>
      </div>

      {/* Error Banner */}
      {error && (
        <div className={styles.errorBanner}>
          <XCircle size={20} />
          <p className={styles.errorMessage}>{error}</p>
        </div>
      )}

      {/* Messages */}
      <div className={styles.chatMessages}>
        <div className={styles.messageContainer}>
          {messages.map((msg, index) => (
            <div
              key={msg.id || msg.tempId || index}
              className={`${styles.messageBubble} ${
                msg.sender === localStorage.getItem('userId') ? styles.sent : styles.received
              }`}
            >
              <p className={styles.messageContent}>{msg.content}</p>
              <div className={styles.messageMeta}>
                <span>{formatTime(msg.timestamp)}</span>
                {msg.status && msg.sender === localStorage.getItem('userId') && (
                  <span className={styles.messageStatus}>
                    {msg.status === 'sent' && <CheckCircle size={12} />}
                    {msg.status === 'failed' && <XCircle size={12} />}
                    {msg.status === 'sending' && (
                      <Clock size={12} className={styles.animateSpin} />
                    )}
                  </span>
                )}
              </div>
            </div>
          ))}
          
          {isTyping && (
            <div className={styles.typingIndicator}>
              <div className={styles.typingDot}></div>
              <div className={styles.typingDot}></div>
              <div className={styles.typingDot}></div>
            </div>
          )}
        </div>
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className={styles.chatInputContainer}>
        <form onSubmit={sendMessage} className={styles.chatInputForm}>
          <input
            type="text"
            className={styles.chatInput}
            value={newMessage}
            onChange={handleTyping}
            placeholder="Type a message"
            disabled={!isConnected}
          />
          <button
            type="submit"
            className={styles.sendButton}
            disabled={!isConnected || !newMessage.trim()}
            aria-label="Send message"
          >
            <Send size={16} strokeWidth={2.5} />
          </button>
        </form>
        {!isConnected && (
          <p className={`${styles.errorMessage} ${styles.textCenter} ${styles.mt2}`}>
            Disconnected. Messages cannot be sent at this time.
          </p>
        )}
      </div>
    </div>
  );
};

export default Chat;