import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const useProfileCompletenessCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkProfileCompleteness = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          console.error('No token found');
          navigate('/login');
          return;
        }

        // Fetch user profile
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const user = response.data;

        // Check if required fields are filled
        const requiredFields = [
          'name', 
          'email', 
          'birthday', 
          'location', 
          'profilePhoto', 
          'galleryImages'
        ];

        const incompleteFields = requiredFields.filter(field => {
          if (field === 'galleryImages') {
            // Check if galleryImages is an array with at least one image
            return !(user[field] && user[field].length > 0);
          }
          // For other fields, check if they are empty or null
          return !(user[field] && user[field].trim() !== '');
        });

        // If there are incomplete fields, redirect to complete profile
        if (incompleteFields.length > 0) {
          console.log('Incomplete profile fields:', incompleteFields);
          navigate('/completeprofile');
        }
      } catch (error) {
        // Detailed error logging
        console.error('Profile check error:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers
        });

        // Handle specific error scenarios
        if (error.response) {
          // The request was made and the server responded with a status code
          switch (error.response.status) {
            case 401:
              console.log('Unauthorized: Invalid or expired token');
              localStorage.removeItem('token');
              navigate('/login');
              break;
            case 404:
              console.log('User profile not found');
              navigate('/completeprofile');
              break;
            case 500:
              console.log('Server error occurred');
              navigate('/error');
              break;
            default:
              console.log('An unexpected error occurred');
              navigate('/login');
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
          navigate('/network-error');
        } else {
          // Something happened in setting up the request
          console.error('Error setting up request:', error.message);
          navigate('/error');
        }
      }
    };

    // Run the profile completeness check
    checkProfileCompleteness();
  }, [navigate]);
};