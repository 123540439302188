import React, { useState, useEffect, useCallback } from 'react';
import { ChevronRight, Check, Camera, X, Loader2 } from 'lucide-react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import styles from '../styles/profileCompletion.module.css';
import { INTERESTS } from './interests';
import { toast } from 'react-toastify';

const ProfileCompletionSteps = ({
  field,
  profileData,
  onNext,
  onSkip,
  uploadingPhoto,
  uploadingGallery,
  isMandatory,
  isLastStep,
}) => {
  const [value, setValue] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isReadyToProceed, setIsReadyToProceed] = useState(false);
  const [isGalleryUploading, setIsGalleryUploading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    // Handle interests pre-selection
    if (field === 'interests' && profileData.interests) {
      const existingInterests = profileData.interests.map(interest => 
        INTERESTS.find(i => i.name === interest)
      ).filter(Boolean);
      setSelectedInterests(existingInterests);
    }
    
    // Set initial value based on field
    setValue(
      profileData[field] || 
      (field === "galleryImages" ? [] : 
       field === 'interests' ? [] : 
       field === 'birthday' ? '' : '')
    );

    // Reset upload error when field changes
    setUploadError(false);
    
    // Reset gallery images for gallery upload field
    if (field === 'galleryImages') {
      const initialImages = profileData[field] || [];
      setGalleryImages(initialImages);
      // Check if initial gallery images meet the minimum requirement
      setIsReadyToProceed(initialImages.length >= 4);
      
      // Also update value with file objects if needed
      setValue(initialImages);
    }

    // Reset gallery upload state
    setIsGalleryUploading(false);

    // Reset preview image for profile photo
    if (field === 'profilePhoto') {
      setPreviewImage(profileData.profilePhoto || null);
    }
  }, [field, profileData]);

  const handleRemoveGalleryImage = (index) => {
    // Prevent removing images during upload or after submission
    if (isGalleryUploading || isSubmitting) return;

    const updatedImages = galleryImages.filter((_, i) => i !== index);
    const updatedFiles = value.filter((_, i) => i !== index);

    setGalleryImages(updatedImages);
    setValue(updatedFiles);
    
    // Update proceed readiness after removal
    if (field === 'galleryImages') {
      setIsReadyToProceed(updatedImages.length >= 4);
    }
  };

  const handleFileChange = async (e) => {
    // Prevent adding images during upload or after submission
    if (isGalleryUploading || isSubmitting) return;

    const files = Array.from(e.target.files);

    try {
      // Validate file sizes (5MB limit)
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      const largeFiles = files.filter(file => file.size > maxFileSize);

      if (largeFiles.length > 0) {
        toast.error('Files exceed the 5MB size limit');
        return;
      }

      // Validate file types
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));

      if (invalidFiles.length > 0) {
        toast.error('Only JPEG, PNG, and GIF images are allowed');
        return;
      }

      if (field === 'galleryImages') {
        // Gallery upload logic (existing code remains the same)
        const newImageUrls = await Promise.all(
          files.map(file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          }))
        );

        const totalImages = galleryImages.length + newImageUrls.length;
        if (totalImages > 6) {
          toast.error('You can only upload a maximum of 6 gallery images');
          return;
        }

        const updatedImages = [...galleryImages, ...newImageUrls].slice(0, 6);
        const updatedFiles = [...(value || []), ...files].slice(0, 6);

        setGalleryImages(updatedImages);
        setValue(updatedFiles);

        if (updatedImages.length >= 4) {
          setIsReadyToProceed(true);
        }
      } else if (field === 'profilePhoto') {
        // Profile photo specific upload logic
        const file = files[0]; // Only take the first file for profile photo
        
        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);

        // Set the file value
        setValue([file]);
        setUploadError(false);
      }
    } catch (error) {
      toast.error('Image upload failed. Please try again.');
      setUploadError(true);
      setValue([]);
      setPreviewImage(null);
    }
  };

  const handleLocationSearch = async (query) => {
    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ 
      query: `${query}, India`,
      countrycodes: 'IN' // Limit to India
    });
    setLocationSuggestions(results);
  };
  
  const handleInterestToggle = (interest) => {
    setSelectedInterests(prev => {
      const isAlreadySelected = prev.some(item => item.name === interest.name);
      if (isAlreadySelected) {
        return prev.filter(item => item.name !== interest.name);
      } else {
        return [...prev, interest];
      }
    });
  };
  
  const renderHeading = () => {
    const fieldMappings = {
      name: "Enter Your Name",
      email: "Add Your Email",
      bio: "Write Your Bio",
      birthday: "Select Your Birthday",
      location: "Choose Your Location",
      profilePhoto: "Upload Profile Photo",
      galleryImages: "Add Gallery Photos",
      interests: "Select Your Interests",
      occupation: "Select Your Occupation"
    };
    return fieldMappings[field] || `Complete Your ${field}`;
  };

  const renderFieldDescription = () => {
    switch (field) {
      case "name": return "Your full name helps others recognize you";
      case "email": return "We'll use this for account communication";
      case "bio": return "Tell us a bit about yourself";
      case "birthday": return "Your age helps in matching compatible connections (DD/MM/YYYY)";
      case "location": return "Where are you located in India?";
      case "profilePhoto": return "A clear photo helps create a great first impression";
      case "galleryImages": return "Upload at least 4 photos (max 6)";
      case "interests": return "Select interests that represent you";
      case "occupation": return "Tell us about your professional background";
      default: return null;
    }
  };

  const handleSubmit = useCallback(async () => {
    // Prevent multiple submissions
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      // Reset upload error flag
      setUploadError(false);

      // Gallery Images handling with specific upload flow
      if (field === 'galleryImages') {
        // Mandatory check
        if (isMandatory && galleryImages.length < 4) {
          toast.error('Please upload at least 4 gallery images');
          return;
        }

        if (galleryImages.length > 6) {
          toast.error('You can only upload a maximum of 6 gallery images');
          return;
        }

        // Start gallery upload process
        setIsGalleryUploading(true);
        toast.info('Uploading gallery images...');

        // Disable image removal and addition during upload
        await onNext(field, value);
        return;
      }

      // Profile Photo handling
      if (field === 'profilePhoto') {
        if (isMandatory && !previewImage) {
          toast.error('Please upload a profile photo');
          return;
        }
        
        toast.info('Uploading profile image...');
        
        await onNext(field, value);
        return;
      }
      
      // Interests handling
      if (field === 'interests') {
        const interestNames = selectedInterests.map(interest => interest.name);
        
        // Check mandatory fields
        if (isMandatory && interestNames.length === 0) {
          toast.error('Please select at least one interest');
          return;
        }

        await onNext(field, interestNames);
        return;
      } 
    
      // Birthday validation
      if (field === 'birthday') {
        const dateFormatRegex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
        
        if (!dateFormatRegex.test(value)) {
          toast.error('Please enter a valid date in DD/MM/YYYY format');
          return;
        }

        const [day, month, year] = value.split('/').map(Number);
        const selectedDate = new Date(year, month - 1, day);
        const today = new Date();
        const minAge = 18;
        const maxAge = 99;

        const age = today.getFullYear() - selectedDate.getFullYear();
        const monthDiff = today.getMonth() - (month - 1);
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < day)) {
          age--;
        }

        if (age < minAge) {
          toast.error(`You must be at least ${minAge} years old`);
          return;
        }

        if (age > maxAge) {
          toast.error(`Please enter a valid birthdate`);
          return;
        }

        await onNext(field, value);
        return;
      }
      
      // Default text fields handling
      if (isMandatory && (!value || value.trim() === '')) {
        toast.error(`Please enter your ${field}`);
        return;
      }

      // For other text-based fields
      await onNext(field, value);
    } catch (error) {
      toast.error('Submission failed. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [field, value, selectedInterests, galleryImages, previewImage, isMandatory, onNext, isSubmitting]);

  const renderProfilePhotoUpload = () => (
    <div className={styles.galleryUploadContainer}>
      <div className={styles.galleryGrid}>
        {previewImage || profileData.profilePhoto ? (
          <div className={styles.galleryItem}>
            <img 
              src={previewImage || profileData.profilePhoto} 
              alt="Profile" 
            />
            {!(uploadingPhoto || isSubmitting) && (
              <button
                type="button"
                className={styles.removeImage}
                onClick={() => {
                  setValue([]);
                  setPreviewImage(null);
                }}
              >
                <X size={16} />
              </button>
            )}
          </div>
        ) : (
          <label className={styles.galleryUpload}>
            <input
              type="file"
              accept="image/jpeg,image/png,image/gif"
              onChange={handleFileChange}
              className={styles.hiddenInput}
              disabled={uploadingPhoto || uploadError || isSubmitting}
            />
            <div className={styles.guploadPlaceholder}>
              <Camera size={24} />
              <span>Upload Photo</span>
            </div>
          </label>
        )}
      </div>
      
      {uploadError && (
        <div className={styles.errorMessage}>
          Upload failed. Please try again or skip.
        </div>
      )}
      
      <p className={`${styles.fieldDescription} ${styles.galleryDescription}`}>
        {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
        {renderFieldDescription()}
      </p>
    </div>
  );

  const renderGalleryUpload = () => (
    <div className={styles.galleryUploadContainer}>
      <div className={styles.galleryGrid}>
        {galleryImages.map((image, index) => (
          <div key={index} className={styles.galleryItem}>
            <img src={image} alt={`Gallery ${index + 1}`} />
            {!(isGalleryUploading || isSubmitting) && (
              <button
                type="button"
                className={styles.removeImage}
                onClick={() => handleRemoveGalleryImage(index)}
              >
                <X size={16} />
              </button>
            )}
          </div>
        ))}
        {galleryImages.length < 6 && !(isGalleryUploading || isSubmitting) && (
          <label className={styles.galleryUpload}>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              className={styles.hiddenInput}
              disabled={isGalleryUploading || isSubmitting}
            />
            <div className={styles.guploadPlaceholder}>
              <Camera size={24} />
              <span>Add Photo</span>
            </div>
          </label>
        )}
      </div>
      <p className={`${styles.fieldDescription} ${styles.galleryDescription}`}>
        {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
        {renderFieldDescription()}
      </p>
    </div>
  );

  const renderField = () => {
    if (field === "galleryImages") {
      return renderGalleryUpload();
    }
    
    if (field === "profilePhoto") {
      return renderProfilePhotoUpload();
    }

    switch (field) {
      case "interests":
        return (
          <div className={styles.interestsContainer}>
            <div className={styles.selectedInterests}>
              {selectedInterests.map(interest => (
                <div 
                  key={interest.name} 
                  className={styles.selectedInterestChip}
                  onClick={() => handleInterestToggle(interest)}
                >
                  {interest.name} ✖️
                </div>
              ))}
            </div>
            <div className={styles.interestGrid}>
              {INTERESTS.map(interest => (
                <div 
                  key={interest.name}
                  className={`${styles.interestItem} ${
                    selectedInterests.some(i => i.name === interest.name) ? styles.selectedInterest : ''
                  }`}
                  onClick={() => handleInterestToggle(interest)}
                >
                  {interest.name}
                </div>
              ))}
            </div>
            <p className={styles.fieldDescription}>
              {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
              {renderFieldDescription()}
            </p>
          </div>
        );
      case "location":
        return (
          <div className={styles.locationContainer}>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                handleLocationSearch(e.target.value);
              }}
              placeholder="Search Location in India"
              className={styles.input}
            />
            {locationSuggestions.length > 0 && (
              <div className={styles.suggestionList}>
                {locationSuggestions.map((location, index) => (
                  <div 
                    key={index} 
                    onClick={() => {
                      setValue(location.label);
                      setSelectedLocation(location);
                      setLocationSuggestions([]);
                    }}
                    className={styles.suggestionItem}
                  >
                    {location.label}
                  </div>
                ))}
              </div>
            )}
            <p className={styles.fieldDescription}>
              {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
              {renderFieldDescription()}
            </p>
          </div>
        );
      case "birthday":
        return (
          <div>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Only allow digits and forward slash
                const formattedValue = inputValue.replace(/[^\d/]/g, '');
                
                // Auto-format to DD/MM/YYYY
                if (formattedValue.length <= 10) {
                  let formatted = formattedValue;
                  
                  // Add slash after day if needed
                  if (formatted.length > 2 && formatted[2] !== '/') {
                    formatted = `${formatted.slice(0, 2)}/${formatted.slice(2)}`;
                  }
                  
                  // Add slash after month if needed
                  if (formatted.length > 5 && formatted[5] !== '/') {
                    formatted = `${formatted.slice(0, 5)}/${formatted.slice(5)}`;
                  }
                  
                  setValue(formatted);
                }
              }}
              placeholder="DD/MM/YYYY"
              className={styles.input}
            />
            <p className={styles.fieldDescription}>
              {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
              {renderFieldDescription()}
            </p>
          </div>
        );
      default:
        return (
          <div>
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={`Enter your ${field}`}
              className={styles.input}
            />
            <p className={styles.fieldDescription}>
              {isMandatory && <span className={styles.mandatoryIndicator}>*</span>}
              {renderFieldDescription()}
            </p>
          </div>
        );
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepContent}>
        <h1>{renderHeading()}</h1>
        {renderField()}
      </div>
      <div className={styles.navigationContainer}>
        {onSkip && (
          <button 
            onClick={onSkip} 
            className={styles.skipButton}
            disabled={uploadingPhoto || uploadingGallery || uploadError || isSubmitting || isGalleryUploading}
          >
            Skip
          </button>
        )}
        <button
          onClick={handleSubmit}
          disabled={
            uploadError ||
            uploadingPhoto || 
            uploadingGallery ||
            isSubmitting ||
            isGalleryUploading ||
            (isMandatory && 
              (field === 'galleryImages' 
               ? !isReadyToProceed 
               : (!value || 
                  (typeof value === 'string' && value.trim() === '') || 
                  (Array.isArray(value) && value.length === 0))))
          }
          className={styles.nextButton}
        >
          {isLastStep ? <Check size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
    </div>
  );
};

export default ProfileCompletionSteps;