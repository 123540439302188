import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Heart, Search, Settings, LogOut } from 'lucide-react';
import BottomNav from './BottomNav';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import styles from '../styles/likedYou.module.css';

const LikedYou = () => {
  useRequireAuth();
  useProfileCompletenessCheck();
  const [likedUsers, setLikedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return false;
    }
    return token;
  }, [navigate]);

  const handleError = useCallback((error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  }, [navigate]);
  
  const fetchLikedUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = checkAuth();
      if (!token) return;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/matches/likes`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Transform the response data to include necessary user information
      const likes = response.data.map(match => ({
        ...match.fromUser,
        matchId: match._id,
        matchDate: match.createdAt
      }));
      
      setLikedUsers(likes);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [checkAuth, handleError]);

  useEffect(() => {
    checkAuth();
    fetchLikedUsers();
  }, [checkAuth, fetchLikedUsers]);

  const filteredUsers = likedUsers.filter(user => 
    user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.username?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short',
      day: 'numeric'
    });
  };

  // If loading, show page-level loading spinner from ForYou page
  if (isLoading) {
    return (
      <div>
        {/* Top Navigation */}
        <div className={styles.topNavbar}>
          <div className={styles.topNavContent}>
            <div className={styles.topNavTitle}>Liked You</div>
          </div>
        </div>
        <div className={styles.loadingContainer}>
          {/* New full-page loading spinner */}
          <div className={styles.loadingSpinner} />
        </div>
        <BottomNav />
      </div>
    );
  }

  return (
  
    <div className={styles.pageContainer}>
      <div className={styles.likedContainer}>
      <div className={styles.topNavbar}>
            <div className={styles.topNavContent}>
              <div className={styles.topNavTitle}>Liked You</div>
            </div>
          </div>
        <div className={styles.likedLayout}>
          <main className={styles.likedMain}>

            <div className={styles.likesHeader}>
              <h2>People who liked you</h2>
              <span>{likedUsers.length} likes</span>
            </div>

            <div className={styles.usersList}>
              {likedUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <button
                    key={user.matchId}
                    className={styles.userCard}
                  >
                    <div className={styles.userAvatarCircle}>
                      <span>{(user.name || user.username || 'U')[0].toUpperCase()}</span>
                    </div>
                    <div className={styles.userInfo}>
                      <p className={styles.name}>{user.name || user.username || 'User'}</p>
                      <p className={styles.status}>Liked you on {formatDate(user.matchDate)}</p>
                    </div>
                    <Heart size={20} className={styles.likeIcon} fill="#FF3366" color="#FF3366" />
                  </button>
                ))
              ) : (
                <div className={styles.emptyState}>
                  <Heart size={24} />
                  <p>No likes yet</p>
                  <p className={styles.emptyStateSubtext}>
                    When someone likes your profile, they'll appear here
                  </p>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default LikedYou;