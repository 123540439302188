import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../styles/bottomNav.module.css';
import { Compass, User, MessageCircle, Award, Heart } from 'lucide-react';

// Navigation items configuration
const navItems = [
  {
    path: '/profile',
    icon: User,
    label: 'Profile'
  },
  {
    path: '/foryou',
    icon: Award,
    label: 'For You'
  },
  {
    path: '/discover',
    icon: Compass,
    label: 'Discover'
  },
  {
    path: '/likedyou',
    icon: Heart,
    label: 'Liked You'
  },
  {
    path: '/inbox',
    icon: MessageCircle,
    label: 'Chats'
  }
];

const BottomNav = () => {
  return (
    <nav className={styles.bottomNav}>
      {navItems.map(({ path, icon: Icon, label }) => (
        <NavLink 
          key={path}
          to={path} 
          className={({ isActive }) => 
            `${styles.navItem} ${isActive ? styles.active : ''}`
          }
        >
          <Icon size={24} />
          <span>{label}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default BottomNav;