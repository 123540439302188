import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useRequireAuth } from './AuthCheck';
import { useProfileCompletenessCheck } from './ProfileCompletenessCheck';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/viewProfile.module.css';

const ViewProfile = () => {
  useRequireAuth();
  useProfileCompletenessCheck();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return false;
    }
    return token;
  }, [navigate]);
  
  const token = checkAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProfile(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to load profile');
        toast.error("Failed to load profile data");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) fetchProfile();
  }, [userId, token]);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader2 className={styles.loadingSpinner} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>Profile not found</div>
      </div>
    );
  }

  return (
    <div className={styles.profileWrapper}>
      <div className={styles.profileContainer}>
        <div className={styles.profileHeader}>
          <div className={styles.profilePhotoSection}>
            <div className={styles.profilePhoto}>
              {profile.profilePhoto ? (
                <img src={profile.profilePhoto} alt={profile.name} />
              ) : (
                <div className={styles.photoPlaceholder}>
                  {profile.name.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
            <h2>{profile.name}</h2>
            {profile.age && profile.location && (
              <p className={styles.subtitle}>
                {profile.age} • {profile.location}
              </p>
            )}
          </div>
        </div>

        <div className={styles.infoSection}>
          {profile.occupation && (
            <div className={styles.infoGroup}>
              <h3>Occupation</h3>
              <p>{profile.occupation}</p>
            </div>
          )}

          {profile.bio && (
            <div className={styles.infoGroup}>
              <h3>About</h3>
              <p>{profile.bio}</p>
            </div>
          )}

          {profile.lookingFor && (
            <div className={styles.infoGroup}>
              <h3>Looking For</h3>
              <p>{profile.lookingFor}</p>
            </div>
          )}

          {profile.interests && profile.interests.length > 0 && (
            <div className={styles.infoGroup}>
              <h3>Interests</h3>
              <div className={styles.interestsContainer}>
                {profile.interests.map((interest, index) => (
                  <div key={index} className={styles.interestTag}>
                    <span>{interest}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {profile.galleryImages && profile.galleryImages.length > 0 && (
          <div className={styles.gallerySection}>
            <h3>Photo Gallery</h3>
            <div className={styles.galleryGrid}>
              {profile.galleryImages.map((image, index) => (
                <div key={index} className={styles.galleryItem}>
                  <img src={image} alt={`${profile.name}'s gallery ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewProfile;